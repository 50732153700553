import React from "react";
import { FaGraduationCap, FaUsers } from "react-icons/fa";
import { GiEvilBook, GiWorld } from "react-icons/gi";

export const Instructor = () => {
  return (
    <>
      <section className="instructor mb-16">
        <div className="container">
          <div className="heading py-12 text-center w-2/3 m-auto md:w-full">
            <h1 className="text-3xl font-semibold text-black">
              What is NotesMafia?
            </h1>
            <span className="text-[14px] mt-2 block">
              NotesMafia is your ultimate destination for seamless online
              learning and teaching. Whether you're a student seeking knowledge
              or an expert ready to share your insights, NotesMafia offers a
              dynamic platform to learn, teach, and thrive. Join a thriving
              community where knowledge is power, and education is accessible to
              everyone.
            </span>
          </div>
          <div className="content grid grid-cols-2 gap-5 md:grid-cols-1">
            <div className="videos rounded-lg overflow-hidden h-72 w-full">
              <iframe
                src="https://www.youtube.com/embed/KeeMLLNpT54"
                title="YouTube video"
                className="rounded-t-lg object-cover w-full h-72"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            <div className="videos rounded-lg overflow-hidden h-72 w-full">
              <iframe
                src="https://www.youtube.com/embed/sYgX96T6xew"
                title="YouTube video"
                className="rounded-t-lg object-cover w-full h-72"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className="content">
            <div className="heading py-12 text-center w-2/3 m-auto md:w-full">
              <h1 className="text-3xl font-semibold text-black">
                We Are Proud
              </h1>
              <span className="text-[14px] mt-2 block">
                You don't have to struggle alone, you've got our assistance and
                help.
              </span>
            </div>
            <div className="content grid grid-cols-4 gap-5 md:grid-cols-2">
              <InstructorCard
                color="text-red-500"
                icon={<FaUsers size={40} />}
                title="63"
                desc="Students Enrolled"
              />
              <InstructorCard
                color="text-orange-500"
                icon={<GiEvilBook size={40} />}
                title="20"
                desc="Total Courses"
              />
              <InstructorCard
                color="text-purple-500"
                icon={<FaGraduationCap size={40} />}
                title="4"
                desc="Online Learners"
              />
              <InstructorCard
                color="text-indigo-500"
                icon={<GiWorld size={40} />}
                title="4"
                desc="Online Learners"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export const InstructorCard = (props) => {
  return (
    <div className={`box p-5 py-5 rounded-md`}>
      <div className={`${props.color}`}>{props.icon}</div>
      <div className="text mt-2">
        <h4 className="text-lg font-semibold text-black">{props.title}</h4>
        <p className="text-[15px]">{props.desc}</p>
      </div>
    </div>
  );
};
