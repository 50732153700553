import React, { useState } from "react";
import axios from "axios";
import "./YouTubeSearch.css";

const YouTubeSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const YOUTUBE_API_KEY = "AIzaSyDB-vI5hZNRXvSo4FpSf5L_USiT-USy1mg";

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&type=video&maxResults=9&q=${searchQuery}&key=${YOUTUBE_API_KEY}`
      );
      setVideos(response.data.items);
    } catch (error) {
      console.error("Error fetching YouTube data", error);
    }
  };

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  return (
    <div className="youtube-search-container">
      {/* Banner with Search Bar */}
      <div className="banner">
        <div className="banner-content">
          <h1>Search YouTube Videos</h1>
          <p>Enjoy free and ad-free access to YouTube videos!</p>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search for YouTube videos..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSearch()}
            />
            <button onClick={handleSearch}>Search</button>
          </div>
        </div>
      </div>

      {/* Video Grid */}
      <div className="video-grid">
        {videos.map((video) => (
          <div
            key={video.id.videoId}
            className="video-card"
            onClick={() => handleVideoSelect(video)}
          >
            <img
              src={video.snippet.thumbnails.high.url}
              alt={video.snippet.title}
              className="thumbnail"
            />
            <h3 className="video-title">{video.snippet.title}</h3>
          </div>
        ))}
      </div>

      {/* Modal for Video Player */}
      {selectedVideo && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>
            <iframe
              width="100%"
              height="500"
              src={`https://www.youtube.com/embed/${selectedVideo.id.videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={selectedVideo.snippet.title}
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default YouTubeSearch;
