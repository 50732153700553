import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const BlogDetails = () => {
  const { id } = useParams(); // Get the blog ID from route params
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SAVE_BLOG}?id=${id}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch blog details.");

        const data = await response.json();
        setBlog(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog details:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [id]); // Run once when the component mounts

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="w-4/5 m-auto py-12">
      <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
      <img
        src={`${process.env.REACT_APP_BASE_URL}${blog.image_path}`}
        alt={blog.title}
        className="w-full rounded-lg mb-6"
      />
      <p className="text-lg text-gray-700 mb-4">{blog.description}</p>
      <div dangerouslySetInnerHTML={{ __html: blog.content }} />
      <p className="mt-4 text-sm text-gray-500">Views: {blog.views}</p>
      <p className="mt-2 text-sm text-gray-500">
        Published on: {new Date(blog.created_at).toLocaleDateString()}
      </p>
    </div>
  );
};
