import React, { useState } from "react";
import "./ContactUs.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields contain at least one non-whitespace character
    const isValid = Object.values(formData).every((value) => /\S/.test(value));

    if (!isValid) {
      alert("Please fill in all fields with at least one word.");
      return;
    }

    const formBody = new FormData();
    formBody.append("name", formData.name);
    formBody.append("email", formData.email);
    formBody.append("phone", formData.phone);
    formBody.append("message", formData.message);

    try {
      const response = await fetch(process.env.REACT_APP_CONTECT, {
        method: "POST",
        body: formBody,
      });
      const result = await response.json();
      alert(result.message || result.error);
    } catch (error) {
      console.error("Failed to send message:", error);
      alert("Failed to send message. Please try again.");
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-form-wrapper">
        <div className="contact-info">
          <h3 className="contact-title">Let's get in touch</h3>
          <p className="contact-text">
            Feel free to reach out to us anytime. We're here to help!
          </p>

          <div className="contact-information">
            <i className="fas fa-map-marker-alt"></i>
            <p>92 Cherry Drive Uniondale, NY 11553</p>
          </div>
          <div className="contact-information">
            <i className="fas fa-envelope"></i>
            <p>lorem@ipsum.com</p>
          </div>
          <div className="contact-information">
            <i className="fas fa-phone"></i>
            <p>123-456-789</p>
          </div>

          <div className="contact-social-media">
            <p>Connect with us :</p>
            <div className="contact-social-icons">
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="contact-form-section">
          <form onSubmit={handleSubmit} autoComplete="off">
            <h3 className="contact-title">Contact us</h3>

            {["name", "email", "phone", "message"].map((field, index) => (
              <div
                key={index}
                className={`contact-input-container ${
                  formData[field] ? "filled" : ""
                }`}
              >
                {field === "message" ? (
                  <textarea
                    name={field}
                    className="contact-input"
                    value={formData[field]}
                    onChange={handleChange}
                    required
                  ></textarea>
                ) : (
                  <input
                    type={
                      field === "email"
                        ? "email"
                        : field === "phone"
                        ? "tel"
                        : "text"
                    }
                    name={field}
                    className="contact-input"
                    value={formData[field]}
                    onChange={handleChange}
                    required
                  />
                )}
                <label>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
              </div>
            ))}

            <input type="submit" value="Send" className="contact-btn" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
