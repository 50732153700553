import React from "react";
import "./InfoCard.css";

const InfoTextCard = ({ tags, onRemoveTag }) => {
  return (
    <>
      <div className="main_info_card">
        <ul>
          {tags.map((tag, index) => (
            <li key={index}>
              {tag}
              <i onClick={() => onRemoveTag(tag)} class="fa-solid fa-xmark"></i>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default InfoTextCard;
