import React from "react";
import { useLocation } from "react-router-dom";
import "./PdfDetails.css"; // Import your custom CSS

const PdfDetails = () => {
  const location = useLocation();
  const { pdfItem } = location.state || {}; // Get passed PDF item from state

  if (!pdfItem) {
    return <div className="no-data">No PDF data available</div>;
  }

  return (
    <div className="pdf-details-container">
      <h1 className="pdf-title">{pdfItem.title}</h1>

      <div className="pdf-metadata">
        <p className="pdf-info">
          <strong>Subject:</strong> {pdfItem.subject}
        </p>
        <p className="pdf-info">
          <strong>Year:</strong> {pdfItem.year}
        </p>
      </div>

      <p className="pdf-description">{pdfItem.description}</p>

      {/* Use PDF.js to render the PDF with full controls */}
      <iframe
        src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=http://localhost/notesmafia/${pdfItem.file_path}`}
        width="100%"
        height="600px"
        title="PDF Viewer"
      ></iframe>
    </div>
  );
};

export default PdfDetails;
