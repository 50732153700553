import React, { useEffect, useState } from "react";
import "./TopUsers.css";
import DaemandMedal from "../../components/assets/images/0.png";
import GoldMedal from "../../components/assets/images/gold-medal.png";
import SilverMedal from "../../components/assets/images/silver-medal.png";
import BronzeMedal from "../../components/assets/images/bronze-medal.png";
import BestSeler from "../../components/assets/images/best-seller.png";
import { useNavigate } from "react-router-dom";

const TopUsers = () => {
  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Use navigate for programmatic navigation

  // Fetch and process top users
  const fetchTopUsers = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_GET_UPLOADS);
      if (!response.ok) {
        throw new Error("Failed to fetch top users");
      }
      const data = await response.json();

      // Group uploads by username and count them
      const userUploads = data.reduce((acc, upload) => {
        const { username } = upload;
        if (!acc[username]) {
          acc[username] = { username, count: 0, views: 0 };
        }
        acc[username].count += 1;
        acc[username].views += upload.views;
        return acc;
      }, {});

      // Convert the object to an array and sort by the number of uploads
      const sortedUsers = Object.values(userUploads).sort(
        (a, b) => b.count - a.count
      );

      setTopUsers(sortedUsers);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopUsers();
  }, []);

  const handleRowClick = (username) => {
    navigate(`/profile/${username}`); // Navigate to the user's profile
  };

  if (loading) return <p>Loading...</p>;

  return (
    <main className="top-users-main">
      <div className="top-users-header">
        <h1>Hall Of Fame</h1>
      </div>
      <div className="top-users-leaderboard">
        <table className="top-users-table">
          <tbody>
            {topUsers.map((user, index) => (
              <tr
                key={user.username}
                onClick={() => handleRowClick(user.username)} // Navigate on row click
                style={{ cursor: "pointer" }} // Indicate clickable rows
              >
                <td className="top-users-number">{index + 1}</td>
                <td className="top-users-name">{user.username}</td>
                <td className="top-users-points">
                  {user.count}
                  <img
                    className="top-users-gold-medal"
                    src={
                      index === 0
                        ? DaemandMedal
                        : index === 1
                        ? GoldMedal
                        : index === 2
                        ? SilverMedal
                        : index === 3
                        ? BronzeMedal
                        : BestSeler
                    }
                    alt="medal"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default TopUsers;
