import React, { useEffect, useState } from "react";
import axios from "axios";

const PopupAd = ({ handlePopupClose }) => {
  const [adCode, setAdCode] = useState(""); // Store ad code
  const [showTime, setShowTime] = useState(null); // Store show time in milliseconds
  const [isVisible, setIsVisible] = useState(false); // Track ad visibility

  // Fetch the active ad and its show time from the backend
  const fetchAd = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_WEBSITE_ADS);
      if (response.data.status === "success" && response.data.data) {
        setAdCode(response.data.data.ad_code); // Set ad content
        setShowTime(response.data.data.show_time * 1000); // Convert seconds to milliseconds
      }
    } catch (error) {
      console.error("Error fetching ad:", error);
    }
  };

  useEffect(() => {
    fetchAd(); // Fetch ad on component mount
  }, []);

  useEffect(() => {
    let showTimer;
    let closeTimer;

    // Display ad after `showTime` (from backend)
    if (showTime) {
      showTimer = setTimeout(() => {
        setIsVisible(true); // Show the ad

        // Automatically hide the ad after the same `showTime` duration
        closeTimer = setTimeout(() => {
          setIsVisible(false); // Hide the ad
        }, showTime);
      }, showTime);
    }

    // Cleanup timers on component unmount
    return () => {
      clearTimeout(showTimer);
      clearTimeout(closeTimer);
    };
  }, [showTime]);

  if (!isVisible || !adCode) return null; // Don't render if ad is not visible or ad code is empty

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg relative w-full max-w-3xl">
        <button
          onClick={handlePopupClose}
          className="absolute top-2 right-2 text-black text-xl"
        >
          &times;
        </button>
        {/* Render ad content safely */}
        <div dangerouslySetInnerHTML={{ __html: adCode }}></div>
      </div>
    </div>
  );
};

export default PopupAd;
