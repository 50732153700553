import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./FileUpload.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import InfoTextCard from "../../components/common/InfoCard/InfoCard";
import allCoursesData from "../../MasterCourse/Course.json"; // Assuming you have a JSON file for courses
import { GloblInfo } from "../../App"; // Assuming you're using a global context for user session

const FileUpload = () => {
  const { userSession } = useContext(GloblInfo); // User session from global context
  const [file, setFile] = useState(null);
  const [fileTitle, setFileTitle] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [semester, setSemester] = useState("");
  const [category, setCategory] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [progressPercent, setProgressPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  console.log("usersseession ", userSession);

  const [collegeName, setCollegeName] = useState("");
  const [collegeList, setCollegeList] = useState([]);

  // Handle search input change for College/University
  const handleCollegeInputChange = (e) => {
    setCollegeName(e.target.value);
    if (e.target.value) {
      fetchCollegeList(e.target.value);
    } else {
      setCollegeList([]);
    }
  };

  // Fetch College/University List based on search input
  const fetchCollegeList = async (name) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_COLLEGE_NAME}?college_name=${name}&limit=15`
      );
      const data = await response.json();
      setCollegeList(data);
    } catch (error) {
      console.error("Error fetching college list:", error);
    }
  };

  // Handle selecting a College/University from the dropdown
  const handleCollegeSelect = (college) => {
    setCollegeName(college.College_Name);
    setCollegeList([]);
  };

  // Handle file input change
  const handleFileChange = (e) => setFile(e.target.files[0]);

  // Add a tag (max 5 tags)
  const handleAddTag = () => {
    if (tagInput && tags.length < 5) {
      setTags([...tags, tagInput]);
      setTagInput("");
    }
  };

  // Remove a tag
  const handleRemoveTag = (tagToRemove) =>
    setTags(tags.filter((tag) => tag !== tagToRemove));

  const handleUpload = async (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (
      !file ||
      !fileTitle ||
      !fileDescription ||
      !collegeName ||
      !selectedCourse ||
      !selectedSubject ||
      !selectedYear ||
      !semester ||
      !category
    ) {
      alert("Please fill all the fields.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", fileTitle);
    formData.append("description", fileDescription);
    formData.append("tags", tags.join(","));
    formData.append("semester", semester);
    formData.append("category", category);
    formData.append("course", selectedCourse);
    formData.append("subject", selectedSubject);
    formData.append("year", selectedYear);
    formData.append("username", userSession.username);
    formData.append("college", collegeName);

    try {
      setLoading(true);

      // Use Axios to track the upload progress
      const response = await axios.post(
        process.env.REACT_APP_UPLOAD_PDF,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgressPercent(percentCompleted);
          },
        }
      );

      const result = response.data;
      alert(result.message);

      // Check if the upload is successful
      if (result.message === "File and image uploaded successfully.") {
        // Navigate to the user's profile page
        navigate(`/profile/${userSession.username}`);
        resetForm(); // Reset form after successful upload
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    } finally {
      setLoading(false);
    }
  };

  // Reset form fields after successful upload
  const resetForm = () => {
    setFile(null);
    setFileTitle("");
    setFileDescription("");
    setTags([]);
    setSemester("");
    setCategory("");
    setSelectedCourse("");
    setSelectedSubject("");
    setSelectedYear("");
    setCollegeName("");
    setProgressPercent(0);
  };

  return (
    <div className="file-upload-container">
      <div className="file-upload-card">
        <h3>Upload Files</h3>

        <div className="file-dropbox">
          {file ? (
            <header>
              <h4>File Selected: {file.name}</h4>
            </header>
          ) : (
            <>
              <header>
                <h4>Select File here</h4>
              </header>
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                id="fileInputID"
                style={{ display: "none" }}
              />
              <label htmlFor="fileInputID" className="upload-btn">
                Choose File
              </label>
            </>
          )}
        </div>

        <div className="form-input-section">
          <div className="form-input-group">
            <label htmlFor="fileTitleInput">File Title</label>
            <input
              type="text"
              id="fileTitleInput"
              placeholder="File Title"
              onChange={(e) => setFileTitle(e.target.value)}
              value={fileTitle}
              required
            />
          </div>
          <div className="form-input-group">
            <label htmlFor="fileDescriptionInput">File Description</label>
            <textarea
              id="fileDescriptionInput"
              placeholder="File Description"
              onChange={(e) => setFileDescription(e.target.value)}
              value={fileDescription}
              required
            />
          </div>
          <div className="form-input-group">
            <label htmlFor="collegeSearchInput">
              Select College/University
            </label>
            <input
              type="text"
              id="collegeSearchInput"
              placeholder="Type College Name"
              value={collegeName}
              onChange={handleCollegeInputChange}
              required
            />
            {collegeList.length > 0 && (
              <ul className="college-dropdown">
                {collegeList.map((college, index) => (
                  <li key={index} onClick={() => handleCollegeSelect(college)}>
                    {college.College_Name}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Course Input */}
          <div className="form-input-group">
            <label htmlFor="courseSelect">Select Course</label>
            <select
              id="courseSelect"
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
              required
            >
              <option value="">Select Course</option>
              {allCoursesData.map((course, index) => (
                <option key={index} value={course.course}>
                  {course.course}
                </option>
              ))}
            </select>
          </div>

          {/* Subject Input */}
          <div className="form-input-group">
            <label htmlFor="subjectSelect">Select Subject</label>
            <select
              id="subjectSelect"
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              required
            >
              <option value="">Select Subject</option>
              {selectedCourse &&
                allCoursesData
                  .find((course) => course.course === selectedCourse)
                  .subjects.map((subject, index) => (
                    <option key={index} value={subject}>
                      {subject}
                    </option>
                  ))}
            </select>
          </div>

          {/* Year Input */}
          <div className="form-input-group">
            <label htmlFor="yearSelect">Select Year</label>
            <select
              id="yearSelect"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              required
            >
              <option value="">Select Year</option>
              <option value="1st">1st Year</option>
              <option value="2nd">2nd Year</option>
              <option value="3rd">3rd Year</option>
              <option value="4th">4th Year</option>
            </select>
          </div>

          {/* Semester Input */}
          <div className="form-input-group">
            <label htmlFor="semesterSelect">Select Semester</label>
            <select
              id="semesterSelect"
              value={semester}
              onChange={(e) => setSemester(e.target.value)}
              required
            >
              <option value="">Select Semester</option>
              <option value="1st">1st Semester</option>
              <option value="2nd">2nd Semester</option>
              <option value="3rd">3rd Semester</option>
              <option value="4th">4th Semester</option>
              <option value="5th">5th Semester</option>
              <option value="6th">6th Semester</option>
              <option value="7th">7th Semester</option>
              <option value="8th">8th Semester</option>
            </select>
          </div>

          {/* Category Input */}
          <div className="form-input-group">
            <label htmlFor="categorySelect">Select Category</label>
            <select
              id="categorySelect"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Select Category</option>
              <option value="Notes">Notes</option>
              <option value="Pdf">Pdf</option>
              <option value="Old_Paper">Old Paper</option>
            </select>
          </div>

          {/* Tags Input */}
          <div className="form-input-group tag-input-group">
            <label htmlFor="tagInput">Add Tag (Max 5)</label>
            <InfoTextCard tags={tags} onRemoveTag={handleRemoveTag} />
            <span style={{ display: "flex", gap: "20px" }}>
              <input
                type="text"
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
                placeholder="Enter tag"
              />
              <button className="upload-btn" onClick={handleAddTag}>
                Add
              </button>
            </span>
          </div>

          {/* Progress Bar and Upload Button */}
          <div className="form-input-group tag-input-group ">
            <ProgressBar
              completed={progressPercent}
              height="15px"
              bgColor="#005af0"
            />
            <button
              className="upload-btn"
              onClick={handleUpload}
              disabled={loading}
            >
              {loading ? "Uploading..." : "Upload File"}
            </button>
          </div>
        </div>
      </div>

      {loading && (
        <div className="loading-bar">
          <Bars color="#005af0" />
        </div>
      )}
    </div>
  );
};

export default FileUpload;
