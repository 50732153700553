import React, { useEffect, useState, useContext } from "react";
import {
  FaBook,
  FaRegBookmark,
  FaBookmark,
  FaUserGraduate,
} from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import { IoEyeSharp } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { GloblInfo } from "../App";
import "./SearchResults.css";

const SearchResults = () => {
  const { userData } = useContext(GloblInfo);
  const [pdfCourses, setPdfCourses] = useState([]);
  const [filteredPdfs, setFilteredPdfs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [savedPdfs, setSavedPdfs] = useState([]);
  const [saveCounts, setSaveCounts] = useState({});

  const location = useLocation(); // Get the current location object
  const queryParams = new URLSearchParams(location.search); // Extract query params
  const category = queryParams.get("category"); // Get 'category' from query params

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);

      // Map the category to match the backend format
      const categoryMap = {
        notes: "Notes",
        "old papers": "Old_Paper",
        books: "Books",
        projects: "Projects",
      };

      const mappedCategory = categoryMap[category?.toLowerCase()] || category;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_GET_UPLOADS}?category=${encodeURIComponent(
            mappedCategory
          )}&page=${page}&limit=6`
        );
        if (!response.ok) throw new Error("Failed to fetch courses");

        const data = await response.json();
        console.log("Fetched Data:", data); // Debugging line
        if (data.length < 6) setHasMore(false);

        setPdfCourses((prev) => [
          ...prev,
          ...data.filter(
            (item) => !prev.some((prevItem) => prevItem.id === item.id)
          ),
        ]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [page, category]); // Adding 'category' as a dependency

  useEffect(() => {
    const fetchSavedPdfs = async () => {
      if (userData && userData.id) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SAVE_PDF}?user_id=${userData.id}`
          );
          const data = await response.json();
          setSavedPdfs(data.map((pdf) => pdf.id));
        } catch (err) {
          console.error("Error fetching saved PDFs:", err);
        }
      }
    };

    fetchSavedPdfs();
  }, [userData]);

  useEffect(() => {
    pdfCourses.forEach((item) => fetchSaveCount(item.id));
  }, [pdfCourses]);

  const fetchSaveCount = async (pdfId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SAVE_PDF}?pdf_id=${pdfId}`
      );
      const data = await response.json();
      if (data.save_count !== undefined) {
        setSaveCounts((prevCounts) => ({
          ...prevCounts,
          [pdfId]: data.save_count,
        }));
      }
    } catch (err) {
      console.error(`Error fetching save count for PDF ${pdfId}:`, err);
    }
  };

  useEffect(() => {
    let result = [...pdfCourses];

    // Filter by category if it's present in the query parameter
    if (category) {
      result = result.filter(
        (item) => item.category.toLowerCase() === category.toLowerCase()
      );
    }

    // Apply additional filters based on user selection
    if (filter === "most_viewed") {
      result = result.sort((a, b) => b.views - a.views);
    } else if (filter === "most_saved") {
      result = result.sort(
        (a, b) => (saveCounts[b.id] || 0) - (saveCounts[a.id] || 0)
      );
    }

    // Apply search filter
    if (searchTerm) {
      result = result.filter(
        (item) =>
          item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.college.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.course.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.semester.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.tags.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredPdfs(result);
  }, [pdfCourses, category, searchTerm, filter, saveCounts]);

  const handleSave = async (pdfId) => {
    const userId = userData?.id;
    if (!userId) return alert("Please log in to save PDFs.");

    try {
      const response = await fetch(process.env.REACT_APP_SAVE_PDF, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: userId, pdf_id: pdfId }),
      });

      const data = await response.json();
      if (data.message === "PDF saved successfully") {
        setSavedPdfs((prev) => [...prev, pdfId]);
        setSaveCounts((prevCounts) => ({
          ...prevCounts,
          [pdfId]: (prevCounts[pdfId] || 0) + 1,
        }));
      }
    } catch (err) {
      console.error("Error saving PDF:", err);
    }
  };

  const handleUnsave = async (pdfId) => {
    const userId = userData?.id;
    if (!userId) return alert("Please log in to unsave PDFs.");

    try {
      const response = await fetch(process.env.REACT_APP_SAVE_PDF, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: userId, pdf_id: pdfId }),
      });

      const data = await response.json();
      if (data.message === "PDF unsaved successfully") {
        setSavedPdfs((prev) => prev.filter((id) => id !== pdfId));
        setSaveCounts((prevCounts) => ({
          ...prevCounts,
          [pdfId]: Math.max((prevCounts[pdfId] || 1) - 1, 0),
        }));
      }
    } catch (err) {
      console.error("Error unsaving PDF:", err);
    }
  };

  const loadMore = () => setPage((prev) => prev + 1);

  if (loading && page === 1) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <section className="courses bg-[#F3F4F8] py-16">
      <div className="w-4/5 m-auto">
        <div className="heading mb-16">
          <h1 className="text-black" style={{ fontSize: "25px" }}>
            Find The Right <br /> Online Notes/PDF/Old Paper For You
          </h1>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: window.innerWidth <= 768 ? "column" : "row",
            gap: window.innerWidth <= 768 ? "8px" : "10px",
            marginBottom: "16px",
          }}
        >
          <input
            type="text"
            placeholder="Search by title, description, username, or college..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              flex: 1,
              padding: window.innerWidth <= 768 ? "8px" : "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              fontSize: window.innerWidth <= 768 ? "14px" : "16px",
            }}
          />
          <button
            onClick={() => setFilter("most_viewed")}
            style={{
              padding: window.innerWidth <= 768 ? "8px 12px" : "10px 16px",
              border: "none",
              borderRadius: "5px",
              fontSize: window.innerWidth <= 768 ? "14px" : "16px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              backgroundColor: filter === "most_viewed" ? "#007bff" : "#f3f4f8",
              color: filter === "most_viewed" ? "#fff" : "#000",
              width: window.innerWidth <= 768 ? "100%" : "auto",
            }}
          >
            Most Viewed
          </button>
          <button
            onClick={() => setFilter("most_saved")}
            style={{
              padding: window.innerWidth <= 768 ? "8px 12px" : "10px 16px",
              border: "none",
              borderRadius: "5px",
              fontSize: window.innerWidth <= 768 ? "14px" : "16px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              backgroundColor: filter === "most_saved" ? "#007bff" : "#f3f4f8",
              color: filter === "most_saved" ? "#fff" : "#000",
              width: window.innerWidth <= 768 ? "100%" : "auto",
            }}
          >
            Most Saved
          </button>
        </div>

        <div className="grid grid-cols-3 gap-8 md:grid-cols-1">
          {filteredPdfs.map((item) => (
            <div key={item.id} className="box rounded-lg shadow-lg bg-white">
              <div className="images rounded-t-lg relative overflow-hidden  w-full">
                <img
                  src={item.image_path} // Use the image path from the API
                  onClick={() => navigate(`/pdf/${item.id}`)}
                  alt={item.title}
                  style={{ height: "250px" }}
                  className="rounded-t-lg object-cover w-full transition ease-in-out delay-150 cursor-pointer hover:scale-125 duration-300"
                />
                <div className="categ flex gap-4 absolute top-0 m-3">
                  <span className="text-[14px] bg-blue-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                    {item.subject}
                  </span>
                  <span className="text-[14px] bg-pink-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                    <h3
                      className="text-black"
                      style={{ fontSize: "15px", color: "white" }}
                    >
                      {item.course.includes("(")
                        ? item.course.match(/\(([^)]+)\)/)[1]
                        : item.course}
                    </h3>
                  </span>
                </div>
              </div>
              <div className="text p-3">
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <FaBook />
                    <span className="text-[14px] ml-2">{item.category}</span>
                  </div>
                  <div className="flex items-center">
                    <MdAccessTime className="text-orange-500" />
                    <span className="text-[14px] ml-2">
                      {item.created_at.split(" ")[0]}
                    </span>
                  </div>
                </div>
                <h3 className="text-lg font-medium">{item.title}</h3>
                <h3 className="text-black font-medium " style={{}}>
                  {item.description.split(" ").slice(0, 7).join(" ")}
                  {item.description.split(" ").length > 7 ? "..." : ""}
                </h3>
                <div style={{ marginBottom: "15px" }}>
                  <h3
                    className="text-black"
                    style={{ fontSize: "15px", height: "50px" }}
                  >
                    {item.college.split("(")[0].trim()}
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      gap: "30px",
                      marginTop: "10px",
                    }}
                  >
                    <span className="text-[14px] bg-blue-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                      Semester : {item.semester}
                    </span>
                    <span className="text-[14px] bg-pink-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                      year : {item.year}
                    </span>
                  </div>
                </div>

                <div className="flex items-center justify-between mt-3">
                  <NavLink to={`/profile/${item.username}`} className="text-sm">
                    <FaUserGraduate /> {item.username}
                  </NavLink>
                  <div className="flex items-center gap-2">
                    <IoEyeSharp /> {item.views}
                    {savedPdfs.includes(item.id) ? (
                      <FaBookmark
                        className="text-blue-500 cursor-pointer"
                        onClick={() => handleUnsave(item.id)}
                      />
                    ) : (
                      <FaRegBookmark
                        className="cursor-pointer"
                        onClick={() => handleSave(item.id)}
                      />
                    )}
                    <span>{saveCounts[item.id] || 0}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {hasMore && (
          <div className="flex justify-center mt-10">
            <button
              onClick={loadMore}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            >
              Show More
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default SearchResults;
