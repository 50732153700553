import React, { useState } from "react";
import axios from "axios";
import "./GoogleBooksSearch.css";

const GoogleBooksSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [books, setBooks] = useState([]);

  const GOOGLE_BOOKS_API_KEY = "AIzaSyDGpWP0xC7T7hS6KALB9kApCShns5NeOn4";

  // Fetch books from Google Books API
  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/books/v1/volumes?q=${searchQuery}&key=${GOOGLE_BOOKS_API_KEY}`
      );
      setBooks(response.data.items || []);
    } catch (error) {
      console.error("Error fetching books", error);
    }
  };

  return (
    <div className="google-books-search-container">
      {/* Banner with Search Input */}
      <div className="banner">
        <div className="banner-content">
          <h1>Search for Books</h1>
          <p>Explore millions of books at your fingertips</p>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search for books..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSearch()}
            />
            <button onClick={handleSearch}>Search</button>
          </div>
        </div>
      </div>

      {/* Book Grid */}
      <div className="book-grid">
        {books.map((book) => (
          <div
            key={book.id}
            className="book-card"
            onClick={() => window.open(book.volumeInfo.previewLink, "_blank")}
          >
            <img
              src={
                book.volumeInfo.imageLinks?.thumbnail ||
                "https://via.placeholder.com/128x192?text=No+Image"
              }
              alt={book.volumeInfo.title}
              className="book-thumbnail"
            />
            <div className="book-info">
              <h3 className="book-title">{book.volumeInfo.title}</h3>
              <p className="book-author">
                {book.volumeInfo.authors?.join(", ") || "Unknown Author"}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoogleBooksSearch;
