import React, { useState, useEffect, useContext, useRef } from "react";

import LogoImg from "../assets/images/logo-black.png";
import MobileLogoImg from "../assets/images/MobileLogoImg.jpg";
import { LinkData } from "../assets/data/dummydata";
import { NavLink, useNavigate } from "react-router-dom";
import { HiOutlineMenuAlt1, HiViewGrid } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { BiUser, BiEdit, BiLogOut } from "react-icons/bi"; // Icons for menu
import { IoMdCloudUpload } from "react-icons/io";
import { Link } from "react-router-dom";
import { GloblInfo } from "../../App";
import "./Header.css";
import "./noti.css";
import { FaBell, FaTimes } from "react-icons/fa"; // Import the bell and close icon

export const Header = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [isOpen, setIsOpen] = useState(false); // State to toggle notifications
  const [notifications, setNotifications] = useState([]); // Store notifications
  const { userSession, setUserSession, userData } = useContext(GloblInfo);
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State for profile menu
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const profileMenuRef = useRef(null); // Reference for profile menu
  const profilePicRef = useRef(null); // Reference for profile picture

  const handleUploadClick = () => {
    navigate("/file-upload"); // Navigate to the 'file-upload' route
  };
  const toggleNotifications = () => {
    setIsOpen(!isOpen);
  };
  // Fetch notifications from the API based on username
  useEffect(() => {
    const fetchNotifications = async () => {
      if (!userSession || !userSession.username) return; // Ensure userSession is available

      try {
        const response = await fetch(
          `${process.env.REACT_APP_NOTIFICATION}?username=${userSession.username}`
        );
        const data = await response.json();
        if (data.status === "success") {
          setNotifications(data.data || []); // Set fetched notifications
        } else {
          console.error("No notifications found");
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications(); // Fetch notifications when the component loads
  }, [userSession]);

  const deleteNotification = async (index) => {
    try {
      const notificationId = notifications[index].id;

      const response = await fetch(
        `${process.env.REACT_APP_NOTIFICATION}?id=${notificationId}`,
        {
          method: "DELETE",
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        // Remove the deleted notification from the local state
        setNotifications(notifications.filter((_, i) => i !== index));
      } else {
        console.error("Error deleting notification:", data.message);
      }
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  // Fetch notifications from the API based on username
  useEffect(() => {
    const fetchNotifications = async () => {
      if (!userSession || !userSession.username) return; // Ensure userSession is available

      try {
        const response = await fetch(
          `${process.env.REACT_APP_NOTIFICATION}?username=${userSession.username}`
        );
        const data = await response.json();
        if (data.status === "success") {
          setNotifications(data.data || []); // Set fetched notifications
        } else {
          console.error("No notifications found");
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications(); // Fetch notifications when the component loads
  }, [userSession]);

  useEffect(() => {
    const savedSession = localStorage.getItem("userSession");
    if (savedSession) {
      setUserSession(JSON.parse(savedSession));
    }
  }, [setUserSession]);

  const handleLogout = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_AUTH, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ logout: true }),
      });
      const data = await response.json();

      if (data.status === "success") {
        // Remove user session from local storage and state
        localStorage.removeItem("userSession");
        setUserSession(null);

        alert("Logged out successfully!");
        navigate("/auth/login");
      } else {
        setErrorMessage("Logout failed");
      }
    } catch (error) {
      setErrorMessage("Error: " + error.message);
    }
  };

  // Toggle profile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Handle outside click to close the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the menu if clicked outside of both the profile menu and profile picture
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target) &&
        !profilePicRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <>
      <header className="bg-white py-4 text-black sticky z-50 shadow-md top-0 left-0 w-full">
        <div className="container flex justify-between items-center">
          <div className="logo flex items-center">
            <Link to="/">
              <img
                src={LogoImg} // Desktop logo image
                alt="logo"
                className="logo-desktop h-5"
                style={{ width: "130px", height: "auto" }}
              />
            </Link>
            <Link to="/">
              <img
                src={MobileLogoImg} // Mobile logo image
                alt="logo"
                className="logo-mobile h-5"
                style={{ width: "30px", height: "auto" }}
              />
            </Link>

            {/* <div className="text_logo">
              <h2 style={{ fontSize: "20px" }}>
                notes<span style={{ color: "red" }}>M</span>afia
              </h2>
            </div> */}
          </div>
          <nav className={open ? "mobile-view" : "desktop-view"}>
            <ul className="flex items-center gap-6">
              {LinkData.map((link) => (
                <li key={link.id} onClick={() => setOpen(null)}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "text-primary text-sm" : "text-[15px]"
                    }
                    to={link.url}
                  >
                    {link.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
          <div className="account flex items-center gap-5 relative">
            {loading ? (
              <div>Loading...</div>
            ) : userSession && userSession.username ? (
              <>
                <div onClick={handleUploadClick} style={{ cursor: "pointer" }}>
                  <IoMdCloudUpload size={30} color="#007BFF" />
                </div>
                <div className="notification-container">
                  <div className="bell-icon" onClick={toggleNotifications}>
                    <FaBell size={24} color="#007BFF" /> {/* Bell icon */}
                    {notifications.length > 0 && (
                      <span className="notification-count">
                        {notifications.length}
                      </span>
                    )}
                  </div>
                  {isOpen && (
                    <ul className="notification-list">
                      {notifications.length === 0 ? (
                        <li className="no-notifications">
                          No new notifications
                        </li>
                      ) : (
                        notifications.map((notification, index) => (
                          <li key={index} className="notification-item">
                            {notification.message}{" "}
                            {/* Display the notification message */}
                            <FaTimes
                              className="delete-icon"
                              color="red"
                              onClick={() => deleteNotification(index)}
                            />
                          </li>
                        ))
                      )}
                    </ul>
                  )}
                </div>

                <div className="username">
                  {" "}
                  <span>{userData?.name?.split(" ")[0] || "user name"}</span>
                </div>
                {/* Profile Picture with click event to toggle the menu */}

                <img
                  src={`${baseUrl}
${userData?.profile_image || "img/user.jpg"}`}
                  alt="User DP"
                  ref={profilePicRef} // Adding reference to profile picture
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={toggleMenu}
                />
                {/* Profile Menu Popup */}
                {menuOpen && (
                  <div
                    className="profile-menu bg-white shadow-lg p-3 rounded-md"
                    style={{
                      position: "absolute",
                      top: "60px",
                      right: "0px",
                      width: "250px",
                      backgroundColor: "white",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      zIndex: 10,
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                    ref={profileMenuRef} // Adding reference to profile menu
                  >
                    <ul className="list-none p-0 m-0 flex flex-col gap-3">
                      <li className="flex items-center gap-3 p-4   cursor-pointer text-lg">
                        <BiUser size={24} />
                        <Link
                          to={`/profile/${userSession.username}`} // Correct dynamic link
                          className="no-underline text-black"
                          onClick={() => setMenuOpen(false)} // Close menu on link click
                        >
                          My Profile
                        </Link>
                      </li>
                      <li className="flex items-center gap-3 p-4   cursor-pointer text-lg">
                        <BiEdit size={24} />
                        <Link
                          to={`/profile/${userSession.username}/edit`}
                          className="no-underline text-black"
                          onClick={() => setMenuOpen(false)} // Close menu on link click
                        >
                          Edit My Profile
                        </Link>
                      </li>
                      <li
                        className="flex items-center gap-3 p-4   cursor-pointer text-lg"
                        onClick={() => {
                          setMenuOpen(false); // Close menu on logout click
                          handleLogout();
                        }}
                      >
                        <BiLogOut size={24} />
                        <span className="text-black">Logout</span>
                      </li>
                    </ul>
                  </div>
                )}
              </>
            ) : (
              <Link to={"/Auth/login"}>
                <button>Login</button>
              </Link>
            )}
            <button className="open-menu" onClick={() => setOpen(!open)}>
              <div className="icon-container">
                <HiOutlineMenuAlt1
                  size={25}
                  className={`icon-transition ${
                    open ? "icon-hidden" : "icon-visible"
                  }`}
                />
                <MdClose
                  size={25}
                  className={`icon-transition ${
                    open ? "icon-visible" : "icon-hidden"
                  }`}
                />
              </div>
            </button>
          </div>
        </div>
        {errorMessage && <p className="error">{errorMessage}</p>}
      </header>
    </>
  );
};
